import whenDomReady from 'when-dom-ready';
import isIe11 from '@js/utils/is-ie11';

async function initialise() {
  if (isIe11()) {
    const videos = document.querySelectorAll('.js-video');

    if (videos && videos.length) {
      const { default: objectFitVideos } = await import('object-fit-videos');
      objectFitVideos(videos);
    }
  }
}

whenDomReady().then(initialise);
