import whenDomReady from 'when-dom-ready';
import isIe11 from '@js/utils/is-ie11';

async function initialise() {
  if (isIe11()) {
    const someImages = document.querySelectorAll('.js-object-fit');

    if (someImages && someImages.length) {
      const { default: objectFitImages } = await import('object-fit-images');

      setTimeout(() => {
        objectFitImages(someImages, { watchMQ: true });
      }, 100);
    }
  }
}

whenDomReady().then(initialise);
