import isIe11 from '@js/utils/is-ie11';

async function initialise() {
  const { default: elementClosest } = await import('element-closest');
  elementClosest(window);
}

if (isIe11()) {
  initialise();
}
